var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'top-controls': _vm.$route.name === 'FrontPage'
  },style:(_vm.$route.name === 'FrontPage'
      ? 'width: 100%; height: 100%'
      : _vm.$vuetify.breakpoint.xsOnly
      ? 'position: relative; width: 100%; height: 50vh;'
      : 'width: 50%;'),attrs:{"id":"map"}},[(_vm.showLiveCoordinates && _vm.latlngLive)?_c('div',{staticClass:"live-coordinates"},[_c('div',[_vm._v("Lat: "+_vm._s(_vm.latlngLive.lat.toFixed(6)))]),_c('div',[_vm._v("Lon: "+_vm._s(_vm.latlngLive.lng.toFixed(6)))])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('map-legend',{attrs:{"is-plans-layer-active":_vm.isPlansLayerActive},on:{"handle:mouseEnter":function($event){return _vm.map.scrollWheelZoom.disable()},"handle:mouseLeave":function($event){return _vm.map.scrollWheelZoom.enable()}}}):_vm._e(),(_vm.$route.name === 'FrontPage')?_c('a',{attrs:{"href":"https://kik.ee","target":"KikWindow","title":_vm.$t('frontpage.kik')}},[_c('img',{staticClass:"kik-logo",class:_vm.$vuetify.breakpoint.smAndDown ? 'kik-logo-sm' : '',attrs:{"src":"https://files.geocollections.info/img/kik_est_logo.png","alt":"kik logo"}})]):_vm._e(),(_vm.$route.name === 'FrontPage')?_c('div',{staticClass:"app-title",class:{
      'app-title-xs': _vm.$vuetify.breakpoint.xsOnly,
      'app-title-sm': _vm.$vuetify.breakpoint.smOnly,
      'app-title-md': _vm.$vuetify.breakpoint.mdOnly,
      'app-title-lg': _vm.$vuetify.breakpoint.lgAndUp
    }},[_vm._v(" "+_vm._s(_vm.$t("frontpage.title"))+" "),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"app-subtitle",class:{
        'app-subtitle-xs': _vm.$vuetify.breakpoint.xsOnly,
        'app-subtitle-sm': _vm.$vuetify.breakpoint.smOnly,
        'app-subtitle-md': _vm.$vuetify.breakpoint.mdOnly,
        'app-subtitle-lg': _vm.$vuetify.breakpoint.lgAndUp
      },domProps:{"innerHTML":_vm._s(_vm.$t('frontpage.subtitle'))}}):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }